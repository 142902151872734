// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-installing-fonts-tsx": () => import("./../../../src/pages/installing-fonts.tsx" /* webpackChunkName: "component---src-pages-installing-fonts-tsx" */),
  "component---src-pages-language-tsx": () => import("./../../../src/pages/language.tsx" /* webpackChunkName: "component---src-pages-language-tsx" */),
  "component---src-pages-phrase-tsx": () => import("./../../../src/pages/phrase.tsx" /* webpackChunkName: "component---src-pages-phrase-tsx" */),
  "component---src-pages-request-tsx": () => import("./../../../src/pages/request.tsx" /* webpackChunkName: "component---src-pages-request-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-language-phrase-tsx": () => import("./../../../src/templates/languagePhrase.tsx" /* webpackChunkName: "component---src-templates-language-phrase-tsx" */),
  "component---src-templates-language-tsx": () => import("./../../../src/templates/language.tsx" /* webpackChunkName: "component---src-templates-language-tsx" */),
  "component---src-templates-phrase-tsx": () => import("./../../../src/templates/phrase.tsx" /* webpackChunkName: "component---src-templates-phrase-tsx" */),
  "component---src-templates-table-view-tsx": () => import("./../../../src/templates/tableView.tsx" /* webpackChunkName: "component---src-templates-table-view-tsx" */)
}

