module.exports = [{
      plugin: require('../plugins/@slnsw/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en"},{"name":"ru"},{"name":"it"},{"name":"ar"},{"name":"fi"},{"name":"ja"},{"name":"de"},{"name":"fr"},{"name":"nl"},{"name":"hu"},{"name":"pt"},{"name":"ro"},{"name":"es"},{"name":"sv"},{"name":"vi"},{"name":"da"},{"name":"tr"}],"fields":[{"name":"Type","store":true},{"name":"RelevantLanguageCode","store":true},{"name":"LanguageName","store":true,"attributes":{"boost":10}},{"name":"PhraseName","store":true,"attributes":{"boost":10}},{"name":"TranslatedPhrase","store":true,"attributes":{"boost":2}},{"name":"ID"},{"name":"Slug","store":true}],"resolvers":{"LanguagesJson":{},"PhrasesJson":{},"TranslationsJson":{}},"filename":"search_index.json","fetchOptions":{"credentials":"same-origin"},"includeOptions":{"include":["^/search$","^/404$"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://multilingual-library-signage.sl.nsw.gov.au","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PFDJ36W","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
